<template>
<div class="row">
  <div class="col-md-12">
    
        <span class="mt-2 h4 titulosg">Editar Información </span> <br>
        <persona :id="idpersona" class=" bg-white p-4 mt-3"></persona>

    </div>
</div>
</template>

<script>
    import persona from '../personas/CrearEditarPersona'
    import { ref, watch, onMounted } from 'vue'    
    import axios from 'axios'

export default {
    components:{
      persona
    },
    props: ['id'],
    setup(props) {

        const idpersona = ref(props.id)

        return {
                 idpersona,
                }
        
    }

}
</script>

<style>

</style>
